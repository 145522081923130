<template>
  <v-row>
    <v-col>
      <v-form>
        <v-text-field
            v-for="(email, index) in updated_emailaddresses"
            :key="index"
            density="compact"
            color="primary"
            variant="underlined"
            :disabled="isDisabled"
            :label="$store.getters.translate('emailaddress')"
            v-model="email.emailaddress">
          <template #append>
            <v-icon @click="deleteEmailaddress(email)" color="red" icon="mdi-trash-can"/>
          </template>
        </v-text-field>
        <v-text-field
            density="compact"
            color="primary"
            variant="underlined"
            :disabled="isDisabled"
            :placeholder="$store.getters.translate('email')"
            v-model="new_emailaddress.emailaddress"
            required>
          <template #append>
            <v-icon @click="add" color="primary" icon="mdi-content-save"/>
          </template>
        </v-text-field>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["modelValue", "is_submit", "isDisabled"],
  data() {
    return {
      updated_emailaddresses: [],
      new_emailaddress: {},
    };
  },
  mounted() {
    this.new_emailaddress = {
      emailaddress: "",
      active: 0
    };
    this.updated_emailaddresses = this.modelValue ? this.modelValue : [];
  },
  methods: {
    add() {
      if(!this.check(this.new_emailaddress.emailaddress)) {
        this.$toast.error(this.$store.getters.translate("please_submit_valid_email"));
      }
      else if (this.new_emailaddress.emailaddress !== "") {
        this.updated_emailaddresses.push(this.new_emailaddress);
        this.new_emailaddress = {
            emailaddress: "",
            active: 0,
          };
        this.$emit("update", this.updated_emailaddresses);
      }
    },
    check(value) {
      let validator = require("email-validator");
      return validator.validate(value);
    },
    deleteEmailaddress(row) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.updated_emailaddresses = this.updated_emailaddresses.filter((record) => record.id !== row.id || record.emailaddress !== row.emailaddress);
          if (this.updated_emailaddresses.length === 0) {
            this.$emit('email_deleted');
          }
          if (row.id) {
            this.$http
                .delete(this.$store.getters.appUrl + "v2/emailaddresses/" + row.id)
                .then(() => {
                  this.$toast.success(this.$store.getters.translate("success"));
                  this.$emit("update", this.updated_emailaddresses);
                }).catch((error) => {
                  if (this.$store.getters.isLoggedIn) {
                    this.$toast.error(error.message);
                  }
                });
          }
        }
      });
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.updated_emailaddresses = this.modelValue ? this.modelValue : [];
      },
    },
    is_submit:function() {
        this.new_emailaddress.emailaddress = '';
    }
  },
};
</script>