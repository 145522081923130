<template>
  <BaseDraggableModal
      name="certificate_import"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="certificate_import">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal('certificate_import')">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate('certificate_files') }}</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ma-5">
            <v-row>
              <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                <v-file-input
                    v-model="new_files"
                    :multiple="true"
                    :label="$store.getters.translate('files')"
                    placeholder="Drop file to upload"
                    class="mt-3"
                    color="primary"
                    bg-color="white"
                    show-size/>
              </v-col>
            </v-row>
            <v-btn size="large" variant="text" color="primary" @click="uploadFiles">IMPORT</v-btn>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.inspectioncertificates,
      loading: false,
      new_files: []
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.new_files = [];
      this.openModal('certificate_import');
    },
    addDropFile(e) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        this.new_files.push(e.dataTransfer.files[i]);
      }
    },
    async uploadFiles() {
      if(this.new_files.length > 0) {
        this.loading = true;
        const formData = new FormData();
        for (let i = 0; i < this.new_files.length; i++) {
          formData.append("attachment[]", this.new_files[i]);
        }
        await this.$http.post(this.$store.getters.appUrl + "v2/" + this.page.name + "/uploadfilesbulk", formData, {headers: {"Content-Type": "multipart/form-data"}});
        this.new_files = [];
        this.$toast.success(this.$store.getters.translate("successfully_saved"));
        this.loading = false;
        this.$emit("refresh");
        this.closeModal('certificate_import');
      }
    },
  }
};
</script>