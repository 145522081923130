<template>
  <BaseDraggableModal
      name="project_management_additional"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="project_management_additional">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal('project_management_additional');">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title v-if="record.project">{{ record.project.name }}</v-toolbar-title>
            <v-spacer/>
            <v-btn size="small" :disabled="loading" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ma-5">
              <v-row>
                <v-col>
                  <v-row>
                    <v-col class="pt-1 pb-1">
                      <v-autocomplete
                          density="compact"
                          color="primary"
                          bg-color="white"
                          variant="underlined"
                          :label="$store.getters.translate('disciplines')"
                          v-model="record.disciplines"
                          :return-object="false"
                          :items='["Electrical", "Instrumentation", "Process", "Automation", "Piping", "Mechanical", "Structural", "HSE", "General"]'
                          multiple/>
                    </v-col>
                  </v-row>
                  <v-row v-for="discipline in record.disciplines">
                    <v-col class="pt-1 pb-1">
                      <v-text-field
                          density="compact"
                          variant="underlined"
                          color="primary"
                          :label="discipline + ' ' + $store.getters.translate('discipline_engineer_name')"
                          v-model="discipline_engineers[discipline]"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col>
                  <v-row>
                    <v-col class="pt-1 pb-1">
                      <v-switch style="height: 45px" color="primary" density="compact" :label="$store.getters.translate('project_engineer_required')" v-model="record.project_engineer_required"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">
                      <v-switch style="height: 45px" color="primary" density="compact" :label="$store.getters.translate('engineering_required')" v-model="record.engineering_required"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">
                      <v-switch style="height: 45px" color="primary" density="compact" :label="$store.getters.translate('construction_required')" v-model="record.construction_required"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">
                      <v-switch style="height: 45px" color="primary" density="compact" :label="$store.getters.translate('commissioning_required')" v-model="record.commissioning_required"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">
                      <v-switch style="height: 45px" color="primary" density="compact" :label="$store.getters.translate('qa_qc_required')" v-model="record.qa_qc_required"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  props: ["record"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.projectmanagement,
      loading: false,
      discipline_engineers: {}
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.discipline_engineers = this.record.discipline_engineers;
      this.openModal('project_management_additional');
    },
    async save() {
      if(!this.loading) {
        this.loading = true;
        this.record.discipline_engineers = this.discipline_engineers;
        await helpFunctions.saveModel(this.$toast, this.page.name, this.record);
        this.loading = false;
      }
    },
  },
};
</script>