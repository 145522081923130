<template>
  <BaseDraggableModal
      name="project_management_preview"
      max-height="80vh"
      width="100vw"
      :fullscreen="true"
      ref="project_management_preview">
    <v-card :loading="loading" :disabled="loading" height="100%" width="100%">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="reset">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ record.name }}</v-toolbar-title>
            <v-spacer/>
            <v-btn size="small" @click="viewTodoList()">
              <v-icon icon="mdi-format-list-checks" size="x-large"/>
            </v-btn>
            <v-btn size="small" @click="viewProjectManagementAdditional()">
              <v-icon icon="mdi-help-circle-outline" size="x-large"/>
            </v-btn>
            <v-btn v-if="record.project && record.project.rootasset && record.project.rootasset.platform_management" size="small" @click="viewPlatformManagement()">
              <v-icon icon="mdi-card-account-phone-outline" size="x-large"/>
            </v-btn>
            <v-btn size="small" @click="editProjectManagement()">
              <v-icon icon="mdi-pencil" size="x-large"/>
            </v-btn>
            <v-btn v-if="canEdit" size="small" :disabled="loading" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ma-5" style="height: 98vh">
              <v-row>
                <v-col>
                  <v-row>
                    <v-col class="pt-1 pb-1"><div style="font-weight: bold;">{{ record.name }}</div></v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider horizontal class="mt-5 mb-5"/>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("workorders") }}:</v-col>
                    <v-col v-if="record.tags" class="pt-1 pb-1">{{ record.tags?.join(", ") }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("afe_pef") }}:</v-col>
                    <v-col v-if="record.project" class="pt-1 pb-1">{{ record.project.afe_pef }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("tw_wbs_code") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.tw_wbs_code }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("project_engineer_name") }}:</v-col>
                    <v-col v-if="record.project && record.project.project_engineer" class="pt-1 pb-1">{{ record.project.project_engineer.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("project_phase") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.project_phase }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("pc_type") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.pc_type }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("discipline_engineer_name") }}:</v-col>
                    <v-col v-if="record.discipline_engineer" class="pt-1 pb-1">{{ record.discipline_engineer.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("root_asset_name") }}:</v-col>
                    <v-col v-if="record.project" class="pt-1 pb-1">{{ record.project.root_asset_name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("area") }}:</v-col>
                    <v-col v-if="record.project && record.project.rootasset && record.project.rootasset.platform_management" class="pt-1 pb-1">{{ record.project.rootasset.platform_management.area }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("cluster") }}:</v-col>
                    <v-col v-if="record.project && record.project.rootasset && record.project.rootasset.platform_management" class="pt-1 pb-1">{{ record.project.rootasset.platform_management.cluster }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("project") }}:</v-col>
                    <v-col v-if="record.project" class="pt-1 pb-1"><a @click="goToProject(record.project.id)">{{ record.project.name }}</a></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("punchlist") }}:</v-col>
                    <v-col v-if="record.project && record.project.punchlist" class="pt-1 pb-1"><a @click="goToPunchlist(record.project.punchlist.id)">{{ record.project.punchlist.name }}</a></v-col>
                  </v-row>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col cols="5">
                  <v-row>
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("engineering_start_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.engineering_start_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("engineering_complete_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.engineering_complete_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("preparations_start_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.preparations_start_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("preparations_complete_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.preparations_complete_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("construction_start_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.construction_start_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("construction_complete_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.construction_complete_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("commissioning_start_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.commissioning_start_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("commissioning_complete_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.commissioning_complete_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7" class="pt-1 pb-1">{{ $store.getters.translate("shutdown_required_construction") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.shutdown_required_construction }}</v-col>
                  </v-row>
                  <v-row v-if="record.shutdown_required_construction === 'Yes'">
                    <v-col cols="7" class="pt-1 pb-1">{{ $store.getters.translate("sr_construction_text") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.sr_construction_text }}</v-col>
                  </v-row>
                  <v-row v-if="record.shutdown_required_construction === 'Yes'">
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("sr_construction_start_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.sr_construction_start_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row v-if="record.shutdown_required_construction === 'Yes'">
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("sr_construction_complete_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.sr_construction_complete_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7" class="pt-1 pb-1">{{ $store.getters.translate("shutdown_required_commissioning") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.shutdown_required_commissioning }}</v-col>
                  </v-row>
                  <v-row v-if="record.shutdown_required_commissioning === 'Yes'">
                    <v-col cols="7" class="pt-1 pb-1">{{ $store.getters.translate("sr_commissioning_text") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.sr_commissioning_text }}</v-col>
                  </v-row>
                  <v-row v-if="record.shutdown_required_commissioning === 'Yes'">
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("sr_commissioning_start_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.sr_commissioning_start_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                  <v-row v-if="record.shutdown_required_commissioning === 'Yes'">
                    <v-col cols="7" class="pt-2 pb-1">{{ $store.getters.translate("sr_commissioning_complete_date") }}:</v-col>
                    <v-col class="pt-0 pb-1">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :disabled="!canEdit" v-model="record.sr_commissioning_complete_date" v-mask="'##-##-####'" placeholder="dd-mm-yyyy"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-switch style="height: 45px" color="primary" density="compact" :label="$store.getters.translate('long_lead_items')" v-model="record.long_lead_items"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-switch style="height: 45px" color="primary" density="compact" :label="$store.getters.translate('sece_involvement')" v-model="record.sece_involvement"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-switch style="height: 45px" color="primary" density="compact" :label="$store.getters.translate('construction_involved')" v-model="record.construction_involved"/>
                    </v-col>
                  </v-row>
                  <v-row v-if="!record.construction_involved">
                    <v-col class="pt-0 pb-0">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :label="$store.getters.translate('construction_involved_text')" v-model="record.construction_involved_text"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <v-switch style="height: 45px" color="primary" density="compact" :label="$store.getters.translate('commissioning_involved')" v-model="record.commissioning_involved"/>
                    </v-col>
                  </v-row>
                  <v-row v-if="!record.commissioning_involved">
                    <v-col class="pt-0 pb-0">
                      <v-text-field style="height: 35px;" density="compact" variant="underlined" color="primary" :label="$store.getters.translate('construction_involved_text')" v-model="record.commissioning_involved_text"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider horizontal class="mt-5 mb-5"/>
              <v-row>
                <v-col class="pt-1 pb-1">
                  <v-card :loading="loading" :disabled="loading">
                    <v-toolbar flat color="white" dense>
                      <v-tabs v-model="tab" align-tabs="start">
                        <v-tab color="primary">{{ $store.getters.translate("meetings") }}</v-tab>
                        <v-tab color="primary">{{ $store.getters.translate("project_engineer_name") }}</v-tab>
                        <v-tab color="primary">{{ $store.getters.translate("construction") }}</v-tab>
                        <v-tab color="primary">{{ $store.getters.translate("commissioning") }}</v-tab>
                        <v-tab color="primary">{{ $store.getters.translate("qa_qc") }}</v-tab>
                        <v-tab color="primary">{{ $store.getters.translate("links_to_other_systems") }}</v-tab>
                      </v-tabs>
                      <v-spacer/>
                      <v-btn v-if="canEditCustomRules && tab !== 0 && tab !== 5" :disabled="loading" size="small" @click="createAnswer()">
                        {{ $store.getters.translate("create") }}
                        <v-icon color="primary" size="large" class="ml-2" icon="mdi-plus"/>
                      </v-btn>
                      <v-btn v-if="canEditCustomRules && tab !== 0 && tab !== 5" :disabled="loading" size="small" @click="editAnswers()">
                        {{ $store.getters.translate("edit") }}
                        <v-icon color="primary" size="large" class="ml-2" icon="mdi-pencil"/>
                      </v-btn>
                      <v-btn v-if="canEditCustomRules && tab !== 0 && tab !== 5" :disabled="loading" size="small" @click="saveAnswers()">
                        {{ $store.getters.translate("save") }}
                        <v-icon color="primary" size="large" class="ml-2" icon="mdi-content-save"/>
                      </v-btn>
                    </v-toolbar>
                    <v-window v-model="tab">
                      <!--MEETINGS-->
                      <v-window-item>
                        <v-card class="ml-5 mr-5" flat elevation="0" rounded="0">
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <base-project-management-log-field ref="meetings_log" :isDisabled="!canEditMeetingLog" :allowInput="true" category="Meetings" type="text" :model_id="record.id" height="300px"/>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <!--Project Engineer-->
                      <v-window-item>
                        <v-card class="ml-5 mr-5" flat elevation="0" rounded="0">
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <base-project-management-log-field :isDisabled="!canEditProjectEngineerLog" :allowInput="true" category="Project Engineer" type="text" :model_id="record.id" height="300px"/>
                              </v-col>
                              <v-divider vertical class="ml-5 mr-5"/>
                              <v-col>
                                <template v-for="(subcategory) in ['Initiate phase (CP0+CP1)', 'Define phase (CP2+CP3)']">
                                  <v-row>
                                    <v-col class="pt-1 pb-1">
                                      {{ subcategory }}
                                    </v-col>
                                  </v-row>
                                  <v-row v-for="(answer) in record.answers.filter(item => item.category === 'Project Engineer' && item.subcategory === subcategory)">
                                    <v-col class="pa-0">
                                      <v-switch style="height: 45px" color="primary" density="compact" :label="answer.name" :disabled="!canEditProjectEngineerLog" v-model="answer.answer" v-on:update:model-value="updateAnswer(answer)"/>
                                    </v-col>
                                  </v-row>
                                </template>
                              </v-col>
                              <v-col>
                                <template v-for="(subcategory) in ['Execute phase (CP4)', 'Execution phase (CP7)']">
                                  <v-row>
                                    <v-col class="pt-1 pb-1">
                                      {{ subcategory }}
                                    </v-col>
                                  </v-row>
                                  <v-row v-for="(answer) in record.answers.filter(item => item.category === 'Project Engineer' && item.subcategory === subcategory)">
                                    <v-col class="pa-0">
                                      <v-switch style="height: 45px" color="primary" density="compact" :label="answer.name" :disabled="!canEditProjectEngineerLog" v-model="answer.answer" v-on:update:model-value="updateAnswer(answer)"/>
                                    </v-col>
                                  </v-row>
                                </template>
                                <v-divider horizontal class="mt-5 mb-5"/>
                                <base-project-management-log-field ref="project_engineer_log" :isDisabled="true" :allowInput="false" category="Project Engineer" type="checkbox" :model_id="record.id" height="150px"/>
                                <v-divider horizontal class="mt-5 mb-5"/>
                                <base-file-list
                                    :page="page"
                                    :parent_id="record.id"
                                    :label="$store.getters.translate('files')"
                                    :files="record.files.filter(file => file.category === 'Project Engineer')"
                                    :deletable="$store.getters.getUser.superuser"
                                    :create="true"
                                    @refresh="load(record.id)"
                                    category="Project Engineer"/>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <!--Construction-->
                      <v-window-item>
                        <v-card class="ml-5 mr-5" flat elevation="0" rounded="0">
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <base-project-management-log-field :isDisabled="!canEditConstructionLog" :allowInput="true" category="Construction" type="text" :model_id="record.id" height="300px"/>
                              </v-col>
                              <v-divider vertical class="ml-5 mr-5"/>
                              <v-col v-for="(subcategory, index) in ['Pre-execution phase', 'Execution phase']">
                                <v-row>
                                  <v-col class="pt-1 pb-1">
                                    {{ subcategory }}
                                  </v-col>
                                </v-row>
                                <v-row v-for="(answer) in record.answers.filter(item => item.category === 'Construction' && item.subcategory === subcategory)">
                                  <v-col class="pa-0">
                                    <v-switch style="height: 45px" color="primary" density="compact" :label="answer.name" :disabled="!canEditConstructionLog" v-model="answer.answer" v-on:update:model-value="updateAnswer(answer)"/>
                                  </v-col>
                                </v-row>
                                <template v-if="index === 1">
                                  <v-divider horizontal class="mt-5 mb-5"/>
                                  <base-project-management-log-field ref="construction_log" :isDisabled="true" :allowInput="false" category="Construction" type="checkbox" :model_id="record.id" height="150px"/>
                                  <v-divider horizontal class="mt-5 mb-5"/>
                                  <base-file-list
                                    :page="page"
                                    :parent_id="record.id"
                                    :label="$store.getters.translate('files')"
                                    :files="record.files.filter(file => file.category === 'Construction')"
                                    :deletable="$store.getters.getUser.superuser"
                                    :create="true"
                                    @refresh="load(record.id)"
                                    category="Construction"/>
                                </template>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <!--Commissioning-->
                      <v-window-item>
                        <v-card class="ml-5 mr-5" flat elevation="0" rounded="0">
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <base-project-management-log-field :isDisabled="!canEditCommissioningLog" :allowInput="true" category="Commissioning" type="text" :model_id="record.id" height="300px"/>
                              </v-col>
                              <v-divider vertical class="ml-5 mr-5"/>
                              <v-col v-for="(subcategory, index) in ['Pre-execution phase', 'Execution phase']">
                                <v-row>
                                  <v-col class="pt-1 pb-1">
                                    {{ subcategory }}
                                  </v-col>
                                </v-row>
                                <v-row v-for="(answer) in record.answers.filter(item => item.category === 'Commissioning' && item.subcategory === subcategory)">
                                  <v-col class="pa-0">
                                    <v-switch style="height: 45px" color="primary" density="compact" :label="answer.name" :disabled="!canEditCommissioningLog" v-model="answer.answer" v-on:update:model-value="updateAnswer(answer)"/>
                                  </v-col>
                                </v-row>
                                <template v-if="index === 1">
                                  <v-divider horizontal class="mt-5 mb-5"/>
                                  <base-project-management-log-field ref="commissioning_log" :isDisabled="true" :allowInput="false" category="Commissioning" type="checkbox" :model_id="record.id" height="150px"/>
                                  <v-divider horizontal class="mt-5 mb-5"/>
                                  <base-file-list
                                    :page="page"
                                    :parent_id="record.id"
                                    :label="$store.getters.translate('files')"
                                    :files="record.files.filter(file => file.category === 'Commissioning')"
                                    :deletable="$store.getters.getUser.superuser"
                                    :create="true"
                                    @refresh="load(record.id)"
                                    category="Commissioning"/>
                                </template>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <!--QA/QC-->
                      <v-window-item>
                        <v-card class="ml-5 mr-5" flat elevation="0" rounded="0">
                          <v-card-text>
                            <v-row>
                              <v-col>
                                <base-project-management-log-field :isDisabled="!canEditQualityLog" :allowInput="true" category="QA/QC" type="text" :model_id="record.id" height="300px"/>
                              </v-col>
                              <v-divider vertical class="ml-5 mr-5"/>
                              <v-col v-for="(subcategory) in ['Pre-execution phase']">
                                <v-row>
                                  <v-col class="pt-1 pb-1">
                                    {{ subcategory }}
                                  </v-col>
                                </v-row>
                                <v-row v-for="(answer) in record.answers.filter(item => item.category === 'QA/QC' && item.subcategory === subcategory)">
                                  <v-col class="pa-0">
                                    <v-switch style="height: 45px" color="primary" density="compact" :label="answer.name" :disabled="!canEditQualityLog" v-model="answer.answer" v-on:update:model-value="updateAnswer(answer)"/>
                                  </v-col>
                                </v-row>
                                <v-divider horizontal class="mt-5 mb-5"/>
                                <base-project-management-log-field ref="qa_qc_log" :isDisabled="true" :allowInput="false" category="QA/QC" type="checkbox" :model_id="record.id" height="150px"/>
                                <v-divider horizontal class="mt-5 mb-5"/>
                                <base-file-list
                                    :page="page"
                                    :parent_id="record.id"
                                    :label="$store.getters.translate('files')"
                                    :files="record.files.filter(file => file.category === 'QA/QC')"
                                    :deletable="$store.getters.getUser.superuser"
                                    :create="true"
                                    @refresh="load(record.id)"
                                    category="QA/QC"/>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                      <!--External Links-->
                      <v-window-item>
                        <v-card class="ml-5 mr-5" flat elevation="0" rounded="0">
                          <v-card-text>
                            <base-link-list ref="base_link_list" :model_type="page.model" :model_id="record.id" :label="$store.getters.translate('links_to_other_systems').toUpperCase()" @createLink="createLink"/>
                          </v-card-text>
                        </v-card>
                      </v-window-item>
                    </v-window>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
        </template>
      </BaseModal>
      <create-project-management-answer ref="create_project_management_answer" :categories="categories" :subcategories="subcategories" :project_management_id="record.id" :current_category="categories[tab-1]" @refresh="load(record.id)"/>
      <edit-project-management-answer ref="edit_project_management_answer" :categories="categories" :subcategories="subcategories" @refresh="load(record.id)"/>
      <create-external-link ref="create_external_link" :model_type="page.model" :model_id="record.id" @refresh="refreshLinkList"/>
      <base-project-management-answers v-if="tab > 0" ref="project_management_answers" :categories="categories" :subcategories="subcategories" :current_category="categories[tab-1]" :answers="record.answers" @edit="editAnswer" @refresh="load(record.id)"/>
      <base-platform-management-preview v-if="record.project && record.project.rootasset && record.project.rootasset.platform_management" ref="platform_management_preview" :platform_management="record.project.rootasset.platform_management"/>
      <base-project-management-additional ref="project_management_additional" :record="record"/>
      <todolist ref="todo_list" :project_management_id="record.id"/>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import CreateExternalLink from "../../views/externallinks/Create";
import BaseFileList from "./BaseFileList";
import BaseLinkList from "./BaseLinkList";
import {useAbility} from "@casl/vue";
import BaseProjectManagementLogField from "./BaseProjectManagementLogField";
import Pusher from 'pusher-js';
import BaseProjectManagementAnswers from "./BaseProjectManagementAnswers";
import CreateProjectManagementAnswer from "../../views/projectmanagementanswers/Create";
import EditProjectManagementAnswer from "../../views/projectmanagementanswers/Edit";
import BasePlatformManagementPreview from "./BasePlatformManagementPreview";
import BaseProjectManagementAdditional from "./BaseProjectManagementAdditional";
import Todolist from "../../views/todolists/Index";

export default {
  components: {
    Todolist,
    BaseProjectManagementAdditional,
    BasePlatformManagementPreview,
    CreateProjectManagementAnswer,
    EditProjectManagementAnswer,
    BaseProjectManagementAnswers,
    BaseProjectManagementLogField,
    BaseLinkList,
    BaseFileList,
    CreateExternalLink,
    BaseModal,
    BaseDraggableModal,
  },
  setup() {
    const { can } = new useAbility();
    return { can }
  },
  data() {
    return {
      page: helpFunctions.pages.projectmanagement,
      loading: false,
      record: {
        files: [],
        answers: [],
        disciplines: [],
        discipline_engineers: {},
      },
      tab: 0,
      employee_id: null,
      has_new_logs: false,
      categories: ["Project Engineer", "Construction", "Commissioning", "QA/QC"],
      subcategories: [],
      pusher: null
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load(id) {
      this.record = {
        files: [],
        answers: [],
        disciplines: [],
        discipline_engineers: {},
      };
      this.subcategories["Project Engineer"] = ["Initiate phase (CP0+CP1)", "Define phase (CP2+CP3)", "Execute phase (CP4)", "Execution phase (CP7)"];
      this.subcategories["Construction"] = ["Pre-execution phase", "Execution phase"];
      this.subcategories["Commissioning"] = ["Pre-execution phase", "Execution phase"];
      this.subcategories["QA/QC"] = ["Pre-execution phase"];
      this.employee_id = this.$store.state.user.person.employee.id;
      this.openModal('project_management_preview');
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toast, this.page.name, id);
      if (this.record.disciplines == null) {
        this.record.disciplines = [];
      }
      if (this.record.discipline_engineers == null) {
        this.record.discipline_engineers = {};
      }
      this.record.answers = this.$lodash.sortBy(this.record.answers, 'sort_order');
      this.record.answers.forEach((answer) => {
        //CAN`T GENERATE IN REAL TIME BECAUSE CATEGORIES AND SUBCATEGORIES HARDCODED AND HAVE EXACT ORDER
        /*if(!this.categories.includes(answer.category)) {
          this.categories.push(answer.category);
        }
        if(!this.subcategories[answer.category]) {
          this.subcategories[answer.category] = [];
        }
        if(!this.subcategories[answer.category].includes(answer.subcategory)) {
          this.subcategories[answer.category].push(answer.subcategory);
        }*/
        answer.edited = false;
      });
      this.loading = false;
      if(!this.pusher) {
        this.registerPusher();
      }
    },
    async save() {
      if(!this.loading) {
        this.loading = true;
        if(await helpFunctions.saveModel(this.$toast, this.page.name, this.record)) {
          await this.load(this.record.id);
        }
        this.loading = false;
      }
    },
    updateAnswer(answer) {
      answer.edited = !answer.edited;
    },
    createAnswer() {
      this.$refs.create_project_management_answer.load();
    },
    editAnswer(answer) {
      this.$refs.edit_project_management_answer.load(answer);
    },
    editAnswers() {
      this.$refs.project_management_answers.load();
    },
    saveAnswers() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/save-project-management-answers", { answers: this.record.answers })
          .then(async () => {
            this.loading = false;
            this.$toast.success(this.$store.getters.translate("success"));
            this.record.answers.forEach((answer) => {
              answer.edited = false;
            });
            if (this.tab === 1) {
              this.$refs.project_engineer_log[0].load();
            }
            else if (this.tab === 2) {
              this.$refs.construction_log[0].load();
            }
            else if (this.tab === 3) {
              this.$refs.commissioning_log[0].load();
            }
            else if (this.tab === 4) {
              this.$refs.qa_qc_log[0].load();
            }
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.response?.data?.error ?? error.message);
            }
            this.loading = false;
          });
    },
    goToProject(id) {
      this.$router.push("/projects/" + id);
    },
    goToPunchlist(id) {
      this.$router.push("/punchlists/" + id);
    },
    editProjectManagement() {
      this.$router.push("/projectmanagement/" + this.record.id);
    },
    viewPlatformManagement() {
      this.$refs.platform_management_preview.load();
    },
    viewProjectManagementAdditional() {
      this.$refs.project_management_additional.load();
    },
    viewTodoList() {
      this.$refs.todo_list.load();
    },
    createLink() {
      this.$refs.create_external_link.load();
    },
    refreshLinkList() {
      this.$refs.base_link_list.load();
    },
    registerPusher() {
      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        encrypted: true,
      });
      Pusher.logToConsole = true;
      this.pusher.connection.bind(('connected'), () => {
        this.$http.defaults.headers.common['X-Socket-Id'] = this.pusher.connection.socket_id;
      })
      this.pusher.subscribe("logsUpdated");
      this.pusher.bind('logs-updated', (data) => {
        if(data.response.sender_id !== this.$store.state.user.id) {
          if (this.tab === 0) {
            this.$refs.meetings_log.load();
          } else {
            this.has_new_logs = true;
          }
        }
      });
    },
    reset() {
      this.pusher.unsubscribe("logsUpdated");
      this.pusher = null;
      this.closeModal('project_management_preview');
    },
  },
  computed: {
    canEdit() {
      return this.can("edit", this.$createEntity(this.page.model.toLowerCase(), { id: 0 })) ||
          this.can("edit", this.$createEntity(this.page.model.toLowerCase(), { id: this.record.id }));
    },
    canEditCustomRules() {
      return (this.tab === 1 && this.canEditProjectEngineerLog) || (this.tab === 2 && this.canEditConstructionLog) ||
          (this.tab === 3 && this.canEditCommissioningLog) || (this.tab === 4 && this.canEditQualityLog);

    },
    canEditMeetingLog() {
      return (this.record.project && this.record.project.project_engineer && this.employee_id === this.record.project.project_engineer.id) ||
          this.can("show", this.$createEntity("meeting", { id: 0 }));
    },
    canEditProjectEngineerLog() {
      return this.record.project && this.record.project.project_engineer && this.employee_id === this.record.project.project_engineer.id;
    },
    canEditConstructionLog() {
      return this.can("show", this.$createEntity("construction", { id: 0 }));
    },
    canEditCommissioningLog() {
      return this.can("show", this.$createEntity("commissioning", { id: 0 }));
    },
    canEditQualityLog() {
      return this.can("show", this.$createEntity("quality", { id: 0 }));
    },
  },
  watch: {
    tab: {
      handler() {
        if(this.tab === 0 && this.has_new_logs) {
          this.$refs.meetings_log.load();
          this.has_new_logs = false;
        }
      },
    },
  },
};
</script>