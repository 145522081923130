<template>
  <textarea
      :disabled="true"
      :style="'height:' + height"
      v-model="new_value"
      :placeholder="$store.getters.translate('log')"
      @click="setFocus()"/>
  <v-text-field
      v-if="allowInput"
      class="custom-text-field"
      variant="underlined"
      color="primary"
      :disabled="isDisabled"
      :placeholder="$store.getters.translate('add_to_log')"
      single-line
      density="compact"
      v-model="new_message"
      @keyup.enter="insertContent"
      hide-details
      ref="input_field">
    <template #append>
      <v-icon @click="!isDisabled && insertContent()" :style="{ cursor: !isDisabled ? 'pointer' : 'default', color: !isDisabled ? 'primary' : 'grey' }" icon="mdi-plus"/>
    </template>
  </v-text-field>
</template>

<script>
import moment from "moment";

export default {
  props: ["category", "type", "model_id", "isDisabled", "allowInput", "height"],
  data() {
    return {
      new_value: null,
      new_message: null,
      set_first_focus: true,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if(this.model_id) {
        this.$http
            .get(this.$store.getters.appUrl + "v2/project-management-logs?category=" + this.category + "&type=" + this.type + "&model_id=" + this.model_id)
            .then((response) => {
              this.new_value = response.data;
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toast.error(error.message);
              }
              this.loading = false;
            });
      }
    },
    setFocus() {
      if (this.set_first_focus && this.allowInput) {
        this.$refs.input_field.focus();
        this.set_first_focus = false;
      }
    },
    insertContent() {
      if (this.new_message) {
        let new_message_signed = "[" + moment().format("DD-MM-YYYY HH:mm:ss") + " - " + this.$store.getters.getUser.person.casual_name + "] " + this.new_message;
        if (!this.new_value) {
          this.new_value = new_message_signed;
        } else {
          this.new_value = new_message_signed + "\n" + this.new_value;
        }
        this.new_message = null;

        this.$http
            .post(this.$store.getters.appUrl + "v2/project-management-logs", {
              category: this.category,
              type: this.type,
              project_management_id: this.model_id,
              new_message_signed: new_message_signed
            })
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toast.error(error.response?.data?.error ?? error.message);
              }
              this.loading = false;
            });
      }
    },
  },
  watch: {
    model_id: {
      handler() {
        this.load();
      },
    },
  },
};
</script>

<style scoped>
textarea {
  padding: 5px;
  border: 1px solid #9E9E9E;
  border-radius: 5px;
  width: 100%;
  font-size: 12px !important;
  margin-bottom: 0 !important;
  resize: none;
}

textarea:focus {
  outline: none !important;
}

input {
  padding: 0 !important;
}

.custom-text-field {
  padding: 5px;
  border: 1px solid #9E9E9E;
  border-radius: 5px;
  width: 100%;
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-bottom: 15px;
}
</style>