<template>
  <BaseDraggableModal
      name="create_external_link"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="create_external_link">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="reset()">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn :disabled="loading" size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ma-5">
              <v-text-field density="compact" color="primary" variant="underlined" :label="$store.getters.translate('name')" v-model="record.name">
                <template #label>{{ $store.getters.translate('name') }}<span class="text-red"><strong>*</strong></span></template>
              </v-text-field>
              <v-text-field density="compact" color="primary" variant="underlined" :label="$store.getters.translate('url')" v-model="record.url" placeholder="https://">
                <template #label>{{ $store.getters.translate('url') }}<span class="text-red"><strong>*</strong></span></template>
              </v-text-field>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["model_type", "model_id"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.externallinks,
      loading: false,
      record: {},
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.openModal('create_external_link');
    },
    async save() {
      if(!this.loading) {
        let usual_fields_required = [{name: 'name', mandatory: true}, {name: 'url', mandatory: true}];
        if (helpFunctions.modelFieldsFilled(this.$toast, this.record, usual_fields_required)) {
          if (!this.record.url.includes("http")) {
            this.$toast.error(this.$store.getters.translate("incorrect_website_format"));
          } else {
            this.loading = true;
            this.record.model_id = this.model_id;
            this.record.model_type = this.model_type;
            if (await helpFunctions.createModel(this.$toast, this.page.name, this.record)) {
              this.$emit("refresh");
              this.reset();
            }
            this.loading = false;
          }
        }
      }
    },
    reset() {
      this.record = {};
      this.closeModal('create_external_link');
    },
  },
};
</script>