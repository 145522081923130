<template>
  <BaseDraggableModal
      name="inspection_import"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="inspection_import">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal('inspection_import')">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate('select_root_asset') }}</v-toolbar-title>
            <v-spacer/>
<!--        <v-btn :disabled="loading" size="small" @click="importInspection">
              <v-icon icon="mdi-import" size="x-large"/>
            </v-btn>-->
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ma-5">
            <v-autocomplete
                density="compact"
                color="primary"
                bg-color="white"
                variant="underlined"
                :items="root_assets"
                v-model="root_asset_id"
                :label="$store.getters.translate('select_root_asset')"
                item-title="name"
                item-value="id"/>
            <v-text-field
                density="compact"
                color="primary"
                variant="underlined"
                :label="$store.getters.translate('gen_project_number')"
                v-model="project_number">
              <template #label>{{ $store.getters.translate('gen_project_number') }}<span class="text-red"><strong>*</strong></span></template>
            </v-text-field>
            <importer
                v-if="root_asset_id && project_number"
                @result="parseResult"
                :fields="blueprint"
                title="Inspections"/>
<!--            REQUIRED WHEN WE IMPORT ORIGINAL EXCEL (CSV) FILE-->
<!--            <v-file-input
                class="mt-3"
                :label="$store.getters.translate('files')"
                show-size
                ref="file_input"
                v-model="file"/>-->
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";
import Importer from "./Importer";

export default {
  components: {
    Importer,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      loading: false,
      root_assets: [],
      locations: [],
      rooms: [],
      line_voltage: [],
      brands: [],
      conformity_marks: [],
      inspection_institutes: [],
      ip_types: [],
      gas_groups: [],
      temperature_classes: [],
      inspection_institute_certifications: [],
      inspection_codes: [],
      equipment_groups: [],
      equipment_categories: [],
      explosive_atmospheres: [],
      regulations: [],
      protection_methods: [],
      equipment_protection_levels: [],
      translations: [],
      equipment_types: [],
      root_asset_id: null,
      project_number: null,
      //file: null,
      blueprint: {
        "name": "Inspections",
        "slug": "inspections",
        "allowAdditionalFields": true,
        "mappingConfidenceThreshold": 0.3,
        "fields": [
          {
            "label":"Tag",
            "key":"tag_number",
            "alternates":[],
            "constraints": [{ "type": "required" }, { "type": "unique" }],
            "type":"string",
          },{
            "label":"Nominaal vermogen in kW",
            "key":"power",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Nominale stroom in A",
            "key":"nominal_current",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Is het object bereikbaar?",
            "key":"reachable",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Type",
            "key":"type",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Model",
            "key":"model",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Omgevingstemperatuur",
            "key":"ambient_temperature",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Zone",
            "key":"zone",
            "alternates":[],
            "validators":[],
            "type":"enum",
            "config": {
              "allow_custom": true,
              "options": [
                  {"value": "None", "label": "Vrij"},
                  {"value": "1", "label": "1"},
                  {"value": "2", "label": "2"},
                  {"value": "?", "label": "?"},
                  {"value": "0", "label": "0"},
                  {"value": "20", "label": "20"},
                  {"value": "21", "label": "21"},
                  {"value": "22", "label": "22"}
              ]
            },
          },{
            "label":"Jaar van certificaat",
            "key":"year",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Certificaatnummer",
            "key":"certificate_number",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Projectnummer",
            "key":"gen_project_number",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"Inspectie Periode",
            "key":"gen_inspection_period",
            "alternates":[],
            "validators":[],
            "type":"string",
          },{
            "label":"General nummer",
            "key":"general_number",
            "alternates":[],
            "validators":[],
            "type":"string",
          }
        ]
      },
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.root_assets = [];
      this.root_asset_id = null;
      this.project_number = null;
      //this.file = null;
      this.openModal('inspection_import');
      this.getRootAssets();
      this.loadLocations();
      this.loadRooms();
      this.loadLineVoltage();
      this.loadBrands();
      this.loadConformityMarks();
      this.loadInspectionInstitutes();
      this.loadIpTypes();
      this.loadGasGroups();
      this.loadTemperatureClasses();
      this.loadInspectionInstituteCertifications();
      this.loadInspectionCodes();
      this.loadEquipmentGroups();
      this.loadEquipmentCategories();
      this.loadExplosiveAtmospheres();
      this.loadRegulations();
      this.loadProtectionMethods();
      this.loadEquipmentProtectionLevels();
      this.loadEquipmentTypes();
    },
    async getRootAssets() {
      this.loading = true;
      let data = await helpFunctions.getRootAssets(this.$toast);
      this.root_assets = this.$lodash.sortBy(data, (o) => o.name.toLowerCase());
      let options = [];
      this.root_assets.forEach((option) => {
        options.push({
          value: option.id,
          label: option.name,
        });
      });
      this.blueprint["fields"].push({
        "label":"Platform",
        "key":"root_asset_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadLocations() {
      this.loading = true;
      this.locations = await helpFunctions.getFullAdditionalModels(this.$toast, "locations");
      this.locations = this.$lodash.sortBy(this.locations, (o) => o.name.toLowerCase());
      let options = [];
      this.locations.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Locatie",
        "key":"location_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadRooms() {
      this.loading = true;
      this.rooms = await helpFunctions.getFullAdditionalModels(this.$toast, "rooms");
      this.rooms = this.$lodash.sortBy(this.rooms, (o) => o.name.toLowerCase());
      let options = [];
      this.rooms.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Room",
        "key":"room_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadLineVoltage() {
      this.loading = true;
      this.line_voltage = await helpFunctions.getFullAdditionalModels(this.$toast, "linevoltage");
      this.line_voltage = this.$lodash.sortBy(this.line_voltage, "voltage");
      let options = [];
      this.line_voltage.forEach((option) => {
        options.push({value: option.id, label: option.voltage + " " + option.ac_dc});
      });
      this.blueprint["fields"].push({
        "label":"Nominale spanning in V",
        "key":"line_voltage_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadBrands() {
      this.loading = true;
      this.brands = await helpFunctions.getFullAdditionalModels(this.$toast, "brands");
      this.brands = this.$lodash.sortBy(this.brands, (o) => o.name.toLowerCase());
      let options = [];
      this.brands.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Fabrikaat",
        "key":"brand_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadConformityMarks() {
      this.loading = true;
      this.conformity_marks = await helpFunctions.getFullAdditionalModels(this.$toast, "conformitymarks");
      this.conformity_marks = this.$lodash.sortBy(this.conformity_marks, (o) => o.name.toLowerCase());
      let options = [];
      this.conformity_marks.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"CE markering",
        "key":"conformity_mark_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadInspectionInstitutes() {
      this.loading = true;
      this.inspection_institutes = await helpFunctions.getFullAdditionalModels(this.$toast, "inspectioninstitutes");
      this.inspection_institutes = this.$lodash.sortBy(this.inspection_institutes, (o) => o.name.toLowerCase());
      let options = [];
      this.inspection_institutes.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Certificering Keuringsinstituut",
        "key":"inspection_institute_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadIpTypes() {
      this.loading = true;
      this.ip_types = await helpFunctions.getFullAdditionalModels(this.$toast, "iptypes");
      this.ip_types = this.$lodash.sortBy(this.ip_types, (o) => o.name.toLowerCase());
      let options = [];
      this.ip_types.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"IP graad",
        "key":"ip_type_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadGasGroups() {
      this.loading = true;
      this.gas_groups = await helpFunctions.getFullAdditionalModels(this.$toast, "gasgroups");
      this.gas_groups = this.$lodash.sortBy(this.gas_groups, (o) => o.name.toLowerCase());
      let options = [];
      this.gas_groups.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Ex-markering Gasgroep",
        "key":"gas_group_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.blueprint["fields"].push({
        "label":"Klantdata Gasgroep",
        "key":"client_gas_group_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadTemperatureClasses() {
      this.loading = true;
      this.temperature_classes = await helpFunctions.getFullAdditionalModels(this.$toast, "temperatureclasses");
      this.temperature_classes = this.$lodash.sortBy(this.temperature_classes, (o) => o.name.toLowerCase());
      let options = [];
      this.temperature_classes.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Ex-markering Temperatuut klasse",
        "key":"temperature_class_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.blueprint["fields"].push({
        "label":"Klantdata Temperatuut klasse",
        "key":"client_temperature_class_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadInspectionInstituteCertifications() {
      this.loading = true;
      this.inspection_institute_certifications = await helpFunctions.getFullAdditionalModels(this.$toast, "inspectioninstitutecertifications");
      this.inspection_institute_certifications = this.$lodash.sortBy(this.inspection_institute_certifications, (o) => o.name.toLowerCase());
      let options = [];
      this.inspection_institute_certifications.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Ex-certificaat Keuringsinstituut",
        "key":"inspection_institute_certification_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadInspectionCodes() {
      this.loading = true;
      this.inspection_codes = await helpFunctions.getFullAdditionalModels(this.$toast, "inspectioncodes");
      this.inspection_codes = this.$lodash.sortBy(this.inspection_codes, (o) => o.code.toLowerCase());
      let options = [];
      this.inspection_codes.forEach((option) => {
        options.push({value: option.id, label: option.code});
      });
      this.blueprint["fields"].push({
        "label":"Toegevoegde letter",
        "key":"inspection_code_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadEquipmentGroups() {
      this.loading = true;
      this.equipment_groups = await helpFunctions.getFullAdditionalModels(this.$toast, "equipmentgroups");
      this.equipment_groups = this.$lodash.sortBy(this.equipment_groups, (o) => o.name.toLowerCase());
      let options = [];
      this.equipment_groups.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Apparatuur Groep",
        "key":"equipment_group_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadEquipmentCategories() {
      this.loading = true;
      this.equipment_categories = await helpFunctions.getFullAdditionalModels(this.$toast, "equipmentcategories");
      this.equipment_categories = this.$lodash.sortBy(this.equipment_categories, (o) => o.name.toLowerCase());
      let options = [];
      this.equipment_categories.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Categorie apparatuur",
        "key":"equipment_category_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadExplosiveAtmospheres() {
      this.loading = true;
      this.explosive_atmospheres = await helpFunctions.getFullAdditionalModels(this.$toast, "explosiveatmospheres");
      this.explosive_atmospheres = this.$lodash.sortBy(this.explosive_atmospheres, (o) => o.name.toLowerCase());
      let options = [];
      this.explosive_atmospheres.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Atmosfeer",
        "key":"explosive_atmosphere_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadRegulations() {
      this.loading = true;
      this.regulations = await helpFunctions.getFullAdditionalModels(this.$toast, "regulations");
      this.regulations = this.$lodash.sortBy(this.regulations, (o) => o.name.toLowerCase());
      let options = [];
      this.regulations.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"Regelgeving",
        "key":"regulation_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadProtectionMethods() {
      this.loading = true;
      this.protection_methods = await helpFunctions.getFullAdditionalModels(this.$toast, "protectionmethods");
      this.protection_methods = this.$lodash.sortBy(this.protection_methods, (o) => o.code.toLowerCase());
      let options = [];
      this.protection_methods.forEach((option) => {
        options.push({value: option.id, label: option.code});
      });
      this.blueprint["fields"].push({
        "label":"Bescherming",
        "key":"protection_method_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadEquipmentProtectionLevels() {
      this.loading = true;
      this.equipment_protection_levels = await helpFunctions.getFullAdditionalModels(this.$toast, "equipmentprotectionlevels");
      this.equipment_protection_levels = this.$lodash.sortBy(this.equipment_protection_levels, (o) => o.name.toLowerCase());
      let options = [];
      this.equipment_protection_levels.forEach((option) => {
        options.push({value: option.id, label: option.name});
      });
      this.blueprint["fields"].push({
        "label":"EPL",
        "key":"equipment_protection_level_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    async loadEquipmentTypes() {
      this.loading = true;
      this.translations = await helpFunctions.getFullAdditionalModels(this.$toast, "translations");
      this.equipment_types = await helpFunctions.getFullAdditionalModels(this.$toast, "equipmenttypes");
      this.equipment_types = this.$lodash.sortBy(this.equipment_types, (o) => o.name.toLowerCase());
      let options = [];
      this.equipment_types.forEach((equipment_type) => {
        let translation_name = this.translations.find(translation => translation.key === equipment_type.name && translation.language === "nl");
        if (!translation_name) {
          translation_name = this.translations.find(translation => translation.key === equipment_type.name && translation.language === "en");
        }
        if (translation_name) {
          options.push({value: equipment_type.id, label: translation_name.value});
        }
        else {
          options.push({value: equipment_type.id, label: equipment_type.name});
        }
      });
      this.blueprint["fields"].push({
        "label":"Materieel soort",
        "key":"equipment_type_id",
        "alternates":[],
        "validators":[],
        "type":"enum",
        "config": {
          "allow_custom": true,
          "options": options
        },
      });
      this.loading = false;
      console.log(this.blueprint);
    },
    parseResult(rows) {
      rows = rows.filter(value => Object.keys(value).length !== 0);
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/import-inspection", { root_asset_id: this.root_asset_id, project_number: this.project_number, rows: rows })
          .then(() => {
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    //FUNCTION FOR UPLOADING CSV FILE
    /*importInspection() {
      if(!this.loading) {
        if(this.file) {
          if(this.project_number) {
            this.loading = true;
            const formData = new FormData();
            formData.append("attachment", this.file);
            formData.append("root_asset_id", this.root_asset_id);
            formData.append("project_number", this.project_number);
            this.$http
                .post(this.$store.getters.appUrl + "v2/import-inspection", formData, {headers: {"Content-Type": "multipart/form-data"}})
                .then(() => {
                  this.loading = false;
                  this.$emit('refresh');
                })
                .catch((error) => {
                  this.$toast.error(error.message);
                  this.loading = false;
                });
          }
          else {
            this.$toast.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('gen_project_number'));
          }
        }
        else {
          this.$toast.error(this.$store.getters.translate("file_not_found"));
        }
      }
    },*/
  }
};
</script>