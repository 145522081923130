<template>
  <BaseDraggableModal
      name="platform_management_preview"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="platform_management_preview">
    <v-card>
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal('platform_management_preview');">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate("platformmanagement") }}</v-toolbar-title>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ma-5">
              <v-row v-if="logo">
                <v-col>
                  <v-layout align-center justify-center column fill-height>
                    <v-img max-width="150" max-height="150" :src="logo" class="ma-auto"/>
                  </v-layout>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("mailbox_unit_planner") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.mailbox_unit_planner }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("offshore_installation_manager") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.offshore_installation_manager }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("teamlead_production") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.teamlead_production }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("teamlead_e_i") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.teamlead_e_i }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("teamlead_mechanical") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.teamlead_mechanical }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("pob") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.pob }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("beds") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.beds }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("not_smoking") }}:</v-col>
                    <v-col class="pt-1 pb-1">
                      <v-icon v-if="platform_management.not_smoking" color="primary" size="large" icon="mdi-check-all"/>
                      <v-icon v-else color="red" size="large" icon="mdi-close"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("telephone_controlroom_platform") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.telephone_controlroom_platform }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("telephone_oim") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.telephone_oim }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("telephone_tl_prod") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.telephone_tl_prod }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("telephone_tl_e_i") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.telephone_tl_e_i }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("telephone_tl_mech") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.telephone_tl_mech }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("telephone_offshore_heli_admin") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.telephone_offshore_heli_admin }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("telephone_pmc") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.telephone_pmc }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("telephone_deckpusher") }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ platform_management.telephone_deckpusher }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!--LIST LINKS-->
                  <base-link-list :model_type="'platformmanagement'" :model_id="platform_management.id" :label="$store.getters.translate('links_to_other_systems')" :preview="true"/>
                </v-col>
              </v-row>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import BaseLinkList from "./BaseLinkList";

export default {
  props: ["platform_management"],
  components: {
    BaseLinkList,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      logo: null
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.openModal('platform_management_preview');
      this.checkLogo();
    },
    checkLogo() {
      if(this.platform_management.files && this.platform_management.files.length > 0) {
        for (let i = 0; i < this.platform_management.files.length; i++) {
          if (this.platform_management.files[i].content_type === "image/png" || this.platform_management.files[i].content_type === "image/jpeg") {
            this.$http
                .get(this.$store.getters.appUrl + "v2/files/" + this.platform_management.files[i].id, {responseType: "blob"})
                .then((response) => {
                  const blob = new Blob([response.data], {type: this.platform_management.files[i].content_type});
                  this.logo = URL.createObjectURL(blob);
                }).catch((error) => {
                  if (this.$store.getters.isLoggedIn) {
                    this.$toast.error(error.message);
                  }
                });
            break;
          }
        }
      }
    },
  },
};
</script>