<template>
    <BaseDraggableModal
        name="todo_list"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.display.xsOnly"
        ref="todo_list">
      <v-card :loading="loading" :disabled="loading">
        <BaseModal>
          <template #header>
            <v-toolbar class="draggable_selector" color="primary" dense flat>
              <v-toolbar-title>{{ page.title }}</v-toolbar-title>
              <v-spacer/>
              <v-btn size="small" @click="closeModal('todo_list');">
                <v-icon icon="mdi-close" size="x-large"/>
              </v-btn>
            </v-toolbar>
          </template>
          <template #content>
            <v-card-text class="ma-5">
              <v-toolbar flat color="white" dense>
                <v-tabs v-model="tab" align-tabs="start">
                  <v-tab color="primary">{{ $store.getters.translate("open_todo_items") }}</v-tab>
                  <v-tab color="primary">{{ $store.getters.translate("closed_todo_items") }}</v-tab>
                  <v-tab color="primary">{{ $store.getters.translate("create_todo_item") }}</v-tab>
                </v-tabs>
              </v-toolbar>
              <v-window v-model="tab">
                <v-window-item>
                  <v-data-table
                      :headers="open_items_headers"
                      :items="todo_lists.filter(item => !item.closed)"
                      :loading="loading"
                      v-bind="footer_props"
                      :show-select="false">
                    <template v-slot:item.actions="{ item }">
                      <v-icon @click="deleteTodoList(item)" color="red" class="mr-2" icon="mdi-trash-can"/>
                      <v-icon @click="closeTodoList(item)" color="primary" class="mr-2" icon="mdi-check-all"/>
                    </template>
                  </v-data-table>
                </v-window-item>
                <v-window-item>
                  <v-data-table
                      :headers="closed_items_headers"
                      :items="todo_lists.filter(item => item.closed)"
                      :loading="loading"
                      v-bind="footer_props"
                      :show-select="false">
                    <template v-slot:item.actions="{ item }">
                      <v-icon @click="deleteTodoList(item)" color="red" class="mr-2" icon="mdi-trash-can"/>
                    </template>
                  </v-data-table>
                </v-window-item>
                <v-window-item>
                  <v-card flat elevation="0" rounded="0">
                    <v-card-text>
                      <v-text-field
                          density="compact"
                          variant="underlined"
                          color="primary"
                          :label="$store.getters.translate('name')"
                          v-model="record.name"/>
                      <v-text-field
                          density="compact"
                          variant="underlined"
                          color="primary"
                          :label="$store.getters.translate('due_date')"
                          v-model="record.due_date"
                          v-mask="'##-##-####'"
                          placeholder="dd-mm-yyyy"/>
                      <v-btn @click="save" :disabled="loading" color="primary" size="large" block>
                        {{ $store.getters.translate("save") }}
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-window-item>
              </v-window>
            </v-card-text>
          </template>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["project_management_id"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.todolists,
      loading: false,
      record: {},
      todo_lists: [],
      open_items_headers: [{ title: this.$store.getters.translate("name"), align: "start", sortable: false, value: "name", width: "80%" },
                          { title: this.$store.getters.translate("due_date"), align: "start", sortable: true, value: "due_date", width: "10%" },
                          { title: this.$store.getters.translate("actions"), sortable: false, align: "right", value: "actions", width: "10%" }],
      closed_items_headers: [{ title: this.$store.getters.translate("name"), align: "start", sortable: false, value: "name", width: "80%" },
                            { title: this.$store.getters.translate("completed_date"), align: "start", sortable: true, value: "completed_date", width: "10%" },
                            { title: this.$store.getters.translate("actions"), sortable: false, align: "right", value: "actions", width: "10%" }],
      footer_props: helpFunctions.footer_props,
      tab: 0,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.record = {};
      this.openModal('todo_list');
      await this.getTodoLists();
    },
    async getTodoLists() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?project_management_id=" + this.project_management_id)
          .then((response) => {
            this.loading = false;
            this.todo_lists = response.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
      });
    },
    async save() {
      if (!this.loading) {
        let usual_fields_required = [{name: 'name', mandatory: true}];
        if (helpFunctions.modelFieldsFilled(this.$toast, this.record, usual_fields_required)) {
          this.loading = true;
          this.record.project_management_id = this.project_management_id;
          await helpFunctions.createModel(this.$toast, this.page.name, this.record);
          this.record = {};
          await this.getTodoLists();
        }
      }
    },
    async deleteTodoList(todo_list) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toast, this.page.name, todo_list.id)) {
            await this.getTodoLists();
          }
          this.loading = false;
        }
      });
    },
    async closeTodoList(todo_list) {
      this.loading = true;
      await helpFunctions.saveModel(this.$toast, this.page.name, todo_list);
      await this.getTodoLists();
    },
  },
};
</script>