<template>
  <BaseDraggableModal
      name="send_project_reports"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="send_project_reports">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="reset()">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate("progress_report") }} {{ moment().format("DD-MM-YYYY") }}</v-toolbar-title>
            <v-spacer/>
            <v-btn size="small" :disabled="loading" @click="sendReports()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ma-5">
            <v-row>
              <v-col>
                {{ $store.getters.translate("high_level_overview") }}
              </v-col>
              <v-col>
                <v-autocomplete
                    density="compact"
                    color="primary"
                    bg-color="white"
                    variant="underlined"
                    :label="$store.getters.translate('template')"
                    v-model="overview_template"
                    :return-object="false"
                    item-value="value"
                    item-title="text"
                    :items="templates"/>
              </v-col>
              <v-col>
                <v-autocomplete
                    density="compact"
                    color="primary"
                    bg-color="white"
                    variant="underlined"
                    :label="$store.getters.translate('user')"
                    v-model="overview_users"
                    :return-object="false"
                    item-value="value"
                    item-title="text"
                    :items="users"
                    multiple/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                {{ $store.getters.translate("high_level_overview_with_e_i") }}
              </v-col>
              <v-col>
                <v-autocomplete
                    density="compact"
                    color="primary"
                    bg-color="white"
                    variant="underlined"
                    :label="$store.getters.translate('template')"
                    v-model="overview_with_e_i_template"
                    :return-object="false"
                    item-value="value"
                    item-title="text"
                    :items="templates"/>
              </v-col>
              <v-col>
                <v-autocomplete
                    density="compact"
                    color="primary"
                    bg-color="white"
                    variant="underlined"
                    :label="$store.getters.translate('user')"
                    v-model="overview_with_e_i_users"
                    :return-object="false"
                    item-value="value"
                    item-title="text"
                    :items="users"
                    multiple/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                {{ $store.getters.translate("high_level_overview_with_p_m") }}
              </v-col>
              <v-col>
                <v-autocomplete
                    density="compact"
                    color="primary"
                    bg-color="white"
                    variant="underlined"
                    :label="$store.getters.translate('template')"
                    v-model="overview_with_p_m_template"
                    :return-object="false"
                    item-value="value"
                    item-title="text"
                    :items="templates"/>
              </v-col>
              <v-col>
                <v-autocomplete
                    density="compact"
                    color="primary"
                    bg-color="white"
                    variant="underlined"
                    :label="$store.getters.translate('user')"
                    v-model="overview_with_p_m_users"
                    :return-object="false"
                    item-value="value"
                    item-title="text"
                    :items="users"
                    multiple/>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["project_id"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      loading: false,
      overview_users: [35, 100],
      overview_with_e_i_users: [89],
      overview_with_p_m_users: [155],
      overview_template: 8,
      overview_with_e_i_template: 9,
      overview_with_p_m_template: 10,
      users: [],
      templates: [],
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.openModal('send_project_reports');
      this.loading = true;
      this.users = await helpFunctions.getAdditionalModelsForDropdowns("users", "id", ["name"]);
      this.templates = await helpFunctions.getAdditionalModelsForDropdowns("templates", "id", ["name"]);
      this.loading = false;
    },
    sendReports() {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/send-project-reports", { project_id: this.project_id, overview_users: this.overview_users,
            overview_with_e_i_users: this.overview_with_e_i_users, overview_with_p_m_users: this.overview_with_p_m_users, overview_template: this.overview_template,
            overview_with_e_i_template: this.overview_with_e_i_template, overview_with_p_m_template: this.overview_with_p_m_template })
          .then(() => {
            this.loading = false;
            this.$toast.success(this.$store.getters.translate("success"));
            this.reset();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    reset() {
      this.overview_users = [35, 100];
      this.overview_with_e_i_users = [89];
      this.overview_with_p_m_users = [155];
      this.overview_template = 8;
      this.overview_with_e_i_template = 9;
      this.overview_with_p_m_template = 10;
      this.closeModal('send_project_reports');
    },
  },
};
</script>