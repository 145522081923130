<template>
  <v-row>
    <v-col>
      <v-card :loading="loading" :disabled="loading">
        <v-toolbar flat color="white" dense>
          <v-autocomplete
              density="compact"
              color="primary"
              bg-color="white"
              variant="underlined"
              class="ml-5 mt-2"
              disabled
              :label="$store.getters.translate('category')"
              v-model="selected_category"
              :return-object="false"
              :items="categories"/>
          <v-autocomplete
              density="compact"
              color="primary"
              bg-color="white"
              variant="underlined"
              class="ml-5 mt-2"
              :label="$store.getters.translate('subcategory')"
              v-model="selected_subcategory"
              :return-object="false"
              :items="subcategories[selected_category]"/>
        </v-toolbar>
        <v-data-table
            id="base_table_project_management_answers"
            :headers="fields"
            :items="getRecords"
            :loading="loading"
            class="elevation-1"
            :show-select="false"
            :items-per-page="100">
          <template #body="{ items }">
            <draggable :list="items" tag="tbody" @end="endDrag(items)" item-key="id">
              <template #item="{ element:answer }">
                <tr>
                  <td><v-icon color="primary" size="small" class="page__grab-icon" icon="mdi-arrow-all"/></td>
                  <td>{{ answer.sort_order }}</td>
                  <td><a @click="$emit('edit', answer)">{{ $store.getters.translate(answer.name) }}</a></td>
                  <td>{{ $store.getters.translate(answer.category) }}</td>
                  <td>{{ $store.getters.translate(answer.subcategory) }}</td>
                  <!--ACTION BUTTONS-->
                  <td style="text-align: right">
                    <v-icon @click="$emit('edit', answer)" color="primary" size="small" class="mr-2" icon="mdi-pencil"/>
                    <v-icon @click="deleteRecord(answer.id)" color="red" size="small" class="mr-2" icon="mdi-trash-can"/>
                  </td>
                </tr>
              </template>
            </draggable>
          </template>
          <template #bottom/>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";
import helpFunctions from "../../plugins/helpFunctions.js";

export default {
  components: { draggable },
  props: ["categories", "subcategories", "current_category", "answers"],
  data() {
    return {
      page: helpFunctions.pages.projectmanagementanswers,
      loading: false,
      records: [],
      fields: helpFunctions.project_management_questions_and_answers,
      selected_subcategory: null,
    };
  },
  beforeMount() {
    document.querySelector('#base_table_project_management_answers > .v-table__wrapper > table > tbody')?.remove();
  },
  mounted() {
    const sortableTbody = document.querySelector('#base_table_project_management_answers > .v-table__wrapper > table > tbody > tbody');
    // <draggable> ruin view in <tbody> (create a <div> tag inside <tbody>)
    //FIX: https://github.com/SortableJS/Vue.Draggable/issues/1212#issuecomment-1918880591
    if (sortableTbody) {
      const tbody = document.querySelector('#base_table_project_management_answers > .v-table__wrapper > table > tbody')
      tbody.parentNode.append(sortableTbody);
      tbody.remove()
    }
  },
  created() {
    this.selected_category = this.current_category;
    this.selected_subcategory = this.subcategories[this.selected_category][0];
    if(!this.fields.find((field) => field.title === this.$store.getters.translate("actions"))) {
      this.fields.push({title: this.$store.getters.translate("actions"), align: "end", fixed: true, sortable: false });
    }
  },
  methods: {
    showRecords() {
      this.records = [];
      for (let i = 0; i < this.answers.length; i++) {
        if (this.answers[i].category.toLowerCase() === this.selected_category.toLowerCase() &&
            this.answers[i].subcategory.toLowerCase() === this.selected_subcategory.toLowerCase()) {
          this.records.push(this.answers[i]);
        }
      }
    },
    save() {
      this.loading = true;
      let temp_records = this.records;
      for (let i = 0; i < temp_records.length; i++) {
        helpFunctions.saveModelSilently(this.page.name, this.records[i]);
      }
      this.loading = false;
      this.$toast.success(this.$store.getters.translate("successfully_saved"));
    },
    endDrag(items) {
      let temp_records = this.records;
      for (let i = 0; i < temp_records.length; i++) {
        for (let z = 0; z < items.length; z++) {
          if (temp_records[i].id === items[z].id && temp_records[i].sort_order !== z + 1) {
            this.records[i].sort_order = z + 1;
          }
        }
      }
      this.records.sort((a, b) => a.sort_order - b.sort_order); // The table was not re-sorted in the previous algorithm, although the values were changed correctly.
    },
    deleteRecord(id) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toast, this.page.name, id)) {
            this.$emit("refresh");
          }
          this.loading = false;
        }
      });
    },
  },
  computed: {
    getRecords() {
      if(this.records) {
        return this.records;
      }
      else {
        return [];
      }
    },
  },
  watch: {
    selected_subcategory: {
      handler() {
        this.showRecords();
      },
    },
  },
};
</script>

<style scoped>
.v-checkbox {
  display: flex;
}
.v-text-field >>> input {
  font-size: 12px !important;
}
.v-text-field >>> label {
  font-size: 12px !important;
}
</style>