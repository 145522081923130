<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="reset()">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title v-if="label">{{ $store.getters.translate(label) }}</v-toolbar-title>
            <v-toolbar-title v-else>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn :disabled="loading" size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ma-5">
              <template v-for="field in usual_fields">
                <template v-if="field.name === 'description' || ((page.model === 'protectionkind' || page.model === 'equipmenttype' || page.model === 'distributiondevicefunction') && field.name === 'name')">
                  <v-text-field
                      density="compact"
                      color="primary"
                      variant="underlined"
                      :key="field.name"
                      :label="$store.getters.translate('en_name_desc')"
                      v-model="english_name"/>
                  <v-text-field
                      density="compact"
                      color="primary"
                      variant="underlined"
                      :key="field.name"
                      :label="$store.getters.translate('nl_name_desc')"
                      v-model="dutch_name"/>
                </template>
                  <!--USUAL FIELDS-->
                <base-field
                    v-else
                    :key="field.name"
                    :modelValue="record[field.name]"
                    :field="field"
                    @change="usualFieldDataChanged"/>
              </template>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import store from "../../store";

export default {
  props: ["modal_name", "page"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      label: null,
      loading: false,
      record: {},
      usual_fields: [],
      english_name: null,
      dutch_name: null,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.record = {};
      this.openModal(this.modal_name);
      await this.getFields();
    },
    async save() {
      if(!this.loading) {
        let key = '';
        if (this.english_name) {
          key = this.$lodash.lowerCase(this.english_name);
        }
        else if (this.dutch_name) {
          key = this.$lodash.lowerCase(this.dutch_name);
        }

        if(key === '') {
          if (this.page.model === 'protectionkind' || this.page.model === 'equipmenttype' || this.page.model === 'distributiondevicefunction') {
            this.$toast.error(store.getters.translate("required_field") + ": " + store.getters.translate('name'));
          }
          else {
            this.$toast.error(store.getters.translate("required_field") + ": " + store.getters.translate('description'));
          }
        }
        else {
          let new_key = key.replaceAll('(', "_").replaceAll(')', "_").replaceAll('[', "_").replaceAll(']', "_")
              .replaceAll('-', "_").replaceAll('/', "_").replaceAll('\\', "_").replace(/ /g, "_");

          if (this.page.model === 'protectionkind' || this.page.model === 'equipmenttype' || this.page.model === 'distributiondevicefunction') {
            this.record.name = new_key;
          }
          else {
            this.record.description = new_key;
          }

          if (helpFunctions.modelFieldsFilled(this.$toast, this.record, this.usual_fields)) {
            this.loading = true;
            let record_id = await helpFunctions.createModel(this.$toast, this.page.name, this.record);
            if(record_id) {
              if(this.english_name) {
                let english_translation = {key: new_key, language: 'en', value: this.english_name};
                await this.createTranslation(english_translation);
              }
              if(this.dutch_name) {
                let dutch_translation = {key: new_key, language: 'nl', value: this.dutch_name};
                await this.createTranslation(dutch_translation);
              }
              await this.$store.dispatch("refreshTranslations");
              this.$emit("refresh", record_id);
              this.reset();
            }
            this.loading = false;
          }
        }
      }
    },
    async createTranslation(translation) {
      await this.$http
          .post(this.$store.getters.appUrl + "v2/translations", translation)
          .then(() => {})
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    reset() {
      this.record = {};
      this.english_name = null;
      this.dutch_name = null;
      this.closeModal(this.modal_name);
    },
    usualFieldDataChanged(data) {
      if(this.record[data[0]] !== data[1]) {
        this.record[data[0]] = data[1];
      }
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_creation");
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
  },
};
</script>