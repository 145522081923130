<template>
  <v-row>
    <v-col>
      <v-card :loading="loading" :disabled="loading">
        <v-toolbar flat color="white" dense>
          <!--TAGS OR INSPECTION DROPDOWN (USED IN ARTICLES ONLY)-->
          <v-autocomplete
              color="primary"
              bg-color="white"
              variant="underlined"
              class="ml-5 mt-2"
              v-if="page.name === 'articles'"
              :label="$store.getters.translate('inspection')"
              v-model="article_filters.inspection_id"
              :return-object="false"
              item-value="value"
              item-title="text"
              :items="$lodash.sortBy(inspections, 'text')"
              @update:model-value="showRecords"
              clearable/>
<!--      <base-tags v-else-if="!base_table_options.no_tags" :modelValue="tags" :modal_name="page.name" @change="searchByTags"/>-->
          <!--TO DO LIST-->
<!--      <v-btn-toggle v-if="page.name === 'documents'" class="ml-10" v-model="show_from_app">
            <v-btn size="x-small" :value="true">{{ $store.getters.translate("to_do_list") }}</v-btn>
            <v-btn size="x-small" :value="false">{{ $store.getters.translate("all") }}</v-btn>
          </v-btn-toggle>-->
          <v-spacer/>
          <!--PROJECTS FILTER BUTTONS-->
          <v-switch v-if="page.name === 'projects'" color="primary" class="mt-5" :label="$store.getters.translate('hide_closed')" v-model="options.hide_closed_projects" @click.stop=""/>
          <!--PUNCHLISTS FILTER BUTTONS-->
          <v-switch v-if="page.name === 'punchlists'" color="primary" class="mt-5" :label="$store.getters.translate('hide_closed')" v-model="options.hide_closed_punchlists" @click.stop=""/>
          <!--PUNCHLISTS FILTER BUTTONS-->
          <v-switch v-if="page.name === 'projectmanagement'" color="primary" class="mt-5" :label="$store.getters.translate('hide_closed')" v-model="options.hide_closed_projectmanagement" @click.stop=""/>
          <!--INSPECTION CERTIFICATE FILTER BUTTONS-->
          <v-switch v-if="page.name === 'inspectioncertificates'" color="primary" class="mt-5" :label="$store.getters.translate('hide_with_files')" v-model="options.hide_with_files" @click.stop=""/>
          <!--RIGHT PART START (BUTTONS)-->
          <v-spacer/>
          <!--CLOSE PROJECTS-->
          <v-btn :disabled="loading" v-if="$store.getters.getUser.superuser && page.name === 'projects' && selected.length > 0" @click.stop="$emit('close', selected);" size="small">
            {{ $store.getters.translate("close") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-check"/>
          </v-btn>
          <!--EXCEL EXPORT-->
          <download-excel v-if="page.name === 'documents'" style="font-size: 12px; text-transform: uppercase; cursor: pointer;" :data="getRecords" :name="page.name + '.xls'">
            {{ $store.getters.translate("export_to_excel") }}
            <v-icon color="primary" size="large" class="ml-2 mr-2" icon="mdi-file-excel"/>
          </download-excel>
          <v-btn v-else @click.stop="showBaseExport" size="small">
            {{ $store.getters.translate("export_to_excel") }}
            <v-icon color="primary" size="large" class="ml-2 mr-2" icon="mdi-file-excel"/>
          </v-btn>
          <!--DELETE-->
          <v-btn :disabled="loading" v-if="selected.length > 0 && canDelete" @click.stop="bulkArchive" size="small">
            {{ $store.getters.translate("delete") }} {{ selected.length }} {{ $store.getters.translate(page.name) }}
            <v-icon color="red" size="large" class="ml-2" icon="mdi-trash-can"/>
          </v-btn>
          <!--DOWNLOAD DOCUMENTS-->
          <v-btn v-if="page.name === 'documents' && selected.length > 0" @click.stop="$emit('download', selected)" size="small">
            {{ $store.getters.translate("download") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-download"/>
          </v-btn>
          <!--MEETINGS-->
          <v-btn v-if="page.name === 'projectmanagement'" @click.stop="$emit('meetings')" size="small">
            {{ $store.getters.translate("meetings") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-monitor-account"/>
          </v-btn>
          <!--IMPORT INSPECTION-->
          <v-btn v-if="page.name === 'inspections' && base_table_options.create && canCreate" @click.stop="$emit('inspectionImport')" size="small">
            {{ $store.getters.translate("import") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-import"/>
          </v-btn>
          <!--IMPORT CERTIFICATES-->
          <v-btn v-if="page.name === 'inspectioncertificates' && base_table_options.create && canCreate" @click.stop="$emit('certificatesImport')" size="small">
            {{ $store.getters.translate("import") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-import"/>
          </v-btn>
          <!--CREATE-->
          <v-btn :disabled="loading" v-if="base_table_options.create && canCreate" @click.stop="$emit('create', true)" size="small">
            {{ $store.getters.translate("create") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-plus"/>
          </v-btn>
          <!--FILTER-->
          <v-btn :disabled="loading" color="primary" @click.stop="showFieldsSettings()" variant="text" size="small">
            <v-icon size="x-large" icon="mdi-filter-variant"/>
          </v-btn>
        </v-toolbar>
        <v-data-table
            @toggle-select-all="() => { this.selected = !this.selected.length ? this.getRecords : []; }"
            :headers="fields"
            :items="getRecords"
            :loading="loading"
            class="elevation-1"
            item-value="id"
            selectable-key="id"
            return-object
            :show-select="!base_table_options.no_select"
            v-model="selected"
            v-bind="footer_props"
            v-model:items-per-page="options.itemsPerPage"
            v-model:page="options.page"
            @update:currentItems="current = $event">
          <template #body.prepend>
            <tr>
              <td v-if="!base_table_options.no_select"/><!--EMPTY TD FOR CHECKBOXES-->
              <td v-for="(key, index) in Object.keys(response_fields)" :key="index">
                <v-autocomplete
                    color="primary"
                    bg-color="white"
                    variant="underlined"
                    v-if="key === 'tags' && !base_table_options.no_tags"
                    :label="$store.getters.translate('tags')"
                    v-model="tags"
                    :return-object="false"
                    item-value="name.en"
                    item-title="name.en"
                    :items="all_tags"
                    @update:model-value="searchByTags"
                    multiple
                    clearable/>
                <v-text-field
                    v-else-if="key !== 'id' && key !== 'tags' && key !== 'created_at' && key !== 'updated_at'"
                    color="primary"
                    variant="underlined"
                    density="compact"
                    v-model="advanced_search[key]"
                    v-debounce:230ms.lock="showRecords"
                    @click:clear="clearSearch(key)"
                    :label="$store.getters.translate(key)"
                    single-line
                    hide-details
                    clearable/>
              </td>
              <td v-if="page.name === 'projects' || (base_table_options.copy && canCreate) || canEdit || canDelete" style="text-align: right"/><!--EMPTY TD FOR ACTIONS-->
            </tr>
          </template>
          <template #body="{ items }">
            <tr style="height: 40px;" v-for="item in items" :key="item.id" @click.ctrl="handleCtrlClick(item)">
              <td style="height: 40px;" v-if="!base_table_options.no_select">
                <v-checkbox style="height: 40px;" color="primary" density="compact" v-model="selected" :key="item.id" :value="item" @click.shift="handleShiftClick(item)"/>
              </td>
              <td style="height: 40px;" v-for="(key, index) in Object.keys(response_fields)" :key="index" @click.exact="$emit('preview', item)">
                <!--CHECKBOXES/BOOLEAN VALUES-->
                <template v-if="response_fields[key] === 'boolean'">
                  <v-icon v-if="item[key]" color="primary" size="large" icon="mdi-check-all"/>
                  <v-icon v-else color="red" size="large" icon="mdi-close"/>
                </template>
                <!--DATES-->
                <template v-else-if="response_fields[key] === 'date' && item[key] && (key === 'date_of_birth' || key === 'created_at' || key === 'updated_at')">
                  {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
                </template>
                <!--DROPDOWNS-->
                <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                  <template v-if="Array.isArray(item[key])">
                    <template v-if="item[key].length > 1">
                      <v-chip style="height: auto;" class="mr-2" size="x-small" v-for="elem in item[key]" :key="elem">
                        {{ elem }}
                      </v-chip>
                    </template>
                    <template v-else>
                      {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 30 }) }}
                    </template>
                  </template>
                  <template v-else>
                    {{ $lodash.truncate($store.getters.translate(item[key]), { length: 30 }) }}
                  </template>
                </template>
                <!--LINKS-->
                <template v-else-if="response_fields[key] === 'link'">
                  <a>
                    <v-avatar v-if="key === 'name' && base_table_options.avatar" class="mr-1" size="20">
                      <v-img v-if="item.avatar" style="border: 1px" :src="item.avatar"/>
                      <v-img v-else-if="item.person && item.person.avatar" style="border: 1px" :src="item.person.avatar"/>
                      <v-img v-else style="border: 1px" src="../../../assets/images/no-user.jpg"/>
                    </v-avatar>
                    <!--CUSTOM FIX FOR TRANSLATION KEYS BECAUSE WE SHOULD SHOW THEM "AS IS"-->
                    <template v-if="page.name === 'translations' && key === 'key'">
                      {{ $lodash.truncate(item[key], { length: 30 }) }}
                    </template>
                    <template v-else>
                      {{ $lodash.truncate($store.getters.translate(item[key]), { length: 30 }) }}
                    </template>
                  </a>
                </template>
                <!--ID FIELDS AND EMAILS-->
                <template v-else-if="key === 'id' || key === 'internal_id' || key === 'email'">
                  <a>{{ item[key] }}</a>
                </template>
                <!--TEXT FIELDS-->
                <template v-else>
                  {{ $lodash.truncate($store.getters.translate(item[key]), { length: 30 }) }}
                </template>
              </td>
              <!--ACTION BUTTONS-->
              <td style="text-align: center; height: 40px;" v-if="page.name === 'projects' || (base_table_options.copy && canCreate) || canEdit || canDelete">
                <v-icon v-if="page.name === 'projects'" @click="viewReport(item)" size="small" class="mr-2" icon="mdi-file-document"/>
                <v-icon v-if="$store.getters.getUser.superuser" @click="viewCustomPermissions(item)" size="small" class="mr-2" icon="mdi-lock-open-outline"/>
                <v-icon v-if="base_table_options.copy && canCreate" @click="makeCopy(item)" size="small" class="mr-2" icon="mdi-content-copy"/>
                <v-icon v-if="canEdit" @click="$emit('edit', item)" size="small" class="mr-2" icon="mdi-pencil"/>
                <v-icon v-if="canDelete" @click="deleteRecord(item.id)" color="red" size="small" class="mr-2" icon="mdi-trash-can"/>
              </td>
            </tr>
            <tr v-if="getRecords.length === 0">
              <td class="text-center text-grey" :colspan="fields.length + 1">
                <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
                <span v-else>{{ $store.getters.translate("loading") }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <base-report ref="base_report"/>
        <base-export ref="base_export" :page="page" :data="getRecords"/>
        <base-modal-fields ref="base_modal_fields" :selected_model="page.model" @refresh="load()"/>
        <base-custom-permissions ref="base_custom_permissions" :selected_model="page.model"/>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BaseReport from "./BaseReport";
import lodash from "lodash";
import helpFunctions from "../../plugins/helpFunctions.js";
import { useAbility } from '@casl/vue';
import BaseExport from "./BaseExport";
import BaseModalFields from "./BaseModalFields";
import BaseCustomPermissions from "./BaseCustomPermissions";

export default {
  components: {BaseCustomPermissions, BaseModalFields, BaseExport, BaseReport },
  props: ["page"],
  setup() {
    const { can } = useAbility()
    return { can }
  },
  data() {
    return {
      loading: false,
      selected: [],
      all_records: [],
      records: [],
      options: {},
      footer_props: helpFunctions.footer_props,
      base_table_options: {},
      advanced_search: {},
      response_fields: {},
      fields: [],
      tags: [],
      documents_limit: 10,
      show_from_app: false,
      current: [],
      //ARTICLE FILTER VARIABLES
      article_filters: {
        inspection_id: null
      },
      inspections: [],
      inspections_articles: {},
      all_tags: [],
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue(this.page.name + "_options")) {
      this.options = helpFunctions.getLocalStorageValue(this.page.name + "_options");
      this.options.hide_closed_projects = true;
      this.options.hide_closed_punchlists = true;
      this.options.hide_closed_projectmanagement = true;
    }
    else {
      this.options = helpFunctions.table_options;
    }
    if(helpFunctions.getLocalStorageValue(this.page.name + "_tags")) {
      this.tags = helpFunctions.getLocalStorageValue(this.page.name + "_tags");
    }
    this.base_table_options = helpFunctions.base_table_options[this.page.name];
    if (this.page.name === 'articles') {
      this.loadInspectionsWithArticleIDs();
    }
    if (!this.base_table_options.no_tags) {
      this.loadTags();
    }
  },
  methods: {
    load: lodash.debounce(function () {
      this.run();
    }, 100),
    run() {
      this.records = [];
      this.all_records = [];
      this.loading = true;
      if (this.page.name === "documents") {
        if(this.show_from_app) {
          this.loadDocumentsForSign();
        }
        else {
          for (let i = 0; i < this.documents_limit; i++) {
            this.loadDocuments(i);
          }
        }
      }
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site")
          .then((response) => {
            if (this.page.name !== "documents") {
              this.loading = false;
              this.all_records = response.data.data;
            }
            this.response_fields = helpFunctions.replaceIDwithNameBaseTable(response.data.fields);
            this.response_fields = helpFunctions.removeClosedField(this.response_fields);
            this.fields = this.composeFields(this.response_fields);
            if (this.page.name !== "documents") {
              this.showRecords();
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    composeFields(fields) {
      let response = [];
      let field_names = Object.keys(fields);
      for (let i = 0; i < field_names.length; i++) {
        response.push({
          title: this.$store.getters.translate(field_names[i]),
          key: field_names[i],
          align: "start",
          fixed: true,
          sortable: true,
          value: field_names[i],
        });
      }
      if(this.page.name === 'projects' || (this.base_table_options.copy && this.canCreate) || this.canEdit || this.canDelete) {
        response.push({
          title: this.$store.getters.translate("actions"),
          align: "center",
          fixed: true,
          sortable: false,
          value: "actions",
        });
      }
      return response;
    },
    showRecords() {
      var sorted_array = [];
      if (this.tags.length > 0) {
        sorted_array = helpFunctions.sortByTagsBase(this.all_records, this.tags);
      }
      else {
        sorted_array = this.all_records;
      }
      if (this.page.name === "articles" && this.article_filters.inspection_id) {
        sorted_array = sorted_array.filter(item => this.inspections_articles[this.article_filters.inspection_id].includes(item.id));
      }
      let field_names = Object.keys(this.response_fields);
      for (let i = 0; i < field_names.length; i++) {
        if (this.advanced_search[field_names[i]] && this.advanced_search[field_names[i]] !== "" && this.advanced_search[field_names[i]].length > 1) {
          sorted_array = sorted_array.filter(item => item[field_names[i]] && item[field_names[i]] !== "" && item[field_names[i]].toLowerCase().includes(this.advanced_search[field_names[i]].toLowerCase()));
          if(sorted_array.length === 0) {
            break;
          }
        }
      }
      this.records = sorted_array;
    },
    clearSearch(key) {
      this.advanced_search[key] = null;
      this.showRecords();
    },
    handleCtrlClick(item) {
      if(this.selected.find((el) => el.id === item.id)) {
        this.selected = this.selected.filter((el) => el.id !== item.id);
      }
      else {
        this.selected.push(item);
      }
    },
    handleShiftClick(item) {
        if (this.selected.length === 1 || this.selected.length === 2) {
          const IDs = [this.selected[1]?.id ?? item.id, this.selected[0].id];    // @click.shift is triggered before v-model="selected" updates, so we need to form a list of IDs from the item.
          let start = this.current.findIndex(el => el.raw.id === Math.min(...IDs));       // getting the starting element index
          let end = this.current.findIndex(el => el.raw.id === Math.max(...IDs));       //  end element index
          if(start > end) {
            let tmp = end;
            end = start;
            start = tmp;
          }
          for (let i = start; i < end; i++) {
            if (!this.selected.find((el) => el.id === this.current[i].key)) {
              this.selected.push(this.current[i].raw);
            }
          }
        }
    },
    searchByTags() {
      helpFunctions.setLocalStorageValue(this.page.name + "_tags", this.tags);
      if (this.all_records && this.all_records.length > 0) {
        this.showRecords();
      } else {
        this.load();
      }
    },
    bulkArchive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
              .post(this.$store.getters.appUrl + "v2/bulk/remove", { model: this.page.model, ids: this.$lodash.map(this.selected, "id") })
              .then(() => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("successfully_deleted"));
                this.selected = [];
                this.load();
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.response?.data?.error ?? error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    async loadDocuments(limit) {
      let start = limit * 10000;
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site" + "&start=" + start)
          .then((response) => {
            if (response.data.data) {
              this.all_records = [...this.all_records, ...response.data.data];
            }
            this.documents_limit--;
            if (this.documents_limit == 0) {
              this.loading = false;
              this.documents_limit = 10;
              this.showRecords();
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    async loadDocumentsForSign() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site" + "&app_docs=true")
          .then((response) => {
            this.loading = false;
            this.all_records = response.data.data;
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    async loadInspectionsWithArticleIDs() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/inspections-with-articles")
          .then((response) => {
            this.loading = false;
            this.inspections = [];
            this.inspections_articles = {};
            response.data.forEach((option) => {
              this.inspections.push({
                value: option.id,
                text: option.gen_project_number,
              });
              this.inspections_articles[option.id] = option.articles;
            });
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async loadTags() {
      this.loading = true;
      this.all_tags = await helpFunctions.getFullAdditionalModels(this.$toast, "tags");
      this.loading = false;
    },
    deleteRecord(id) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.deleteAction(id);
        }
      });
    },
    async deleteAction(id) {
      this.loading = true;
      this.$http
          .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
          .then(() => {
            this.loading = false;
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    makeCopy(item) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          item.model = this.page.model;
          this.$http
              .post(this.$store.getters.appUrl + "v2/bulk/copy", item)
              .then(() => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("success"));
                this.selected = [];
                this.load();
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.response?.data?.error ?? error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    viewReport(item) {
      this.$refs.base_report.load(item);
    },
    viewCustomPermissions(item) {
      this.$refs.base_custom_permissions.load(this.page.model, item.id);
    },
    showBaseExport() {
      this.$refs.base_export.load();
    },
    showFieldsSettings() {
      this.$refs.base_modal_fields.load();
    },
  },
  computed: {
    getRecords() {
      if(this.page.name === 'inspectioncertificates' && this.options.hide_with_files) {
        return this.records.filter((record) => !record.files || record.files.length === 0);
      }
      else if(this.page.name === 'projects' && this.options.hide_closed_projects) {
        return this.records.filter((record) => !record.closed);
      }
      else if(this.page.name === 'punchlists' && this.options.hide_closed_punchlists) {
        return this.records.filter((record) => !record.closed);
      }
      else if(this.page.name === 'projectmanagement' && this.options.hide_closed_projectmanagement) {
        return this.records.filter((record) => !record.closed);
      }
      else if(this.records) {
        return this.records;
      }
      else {
        return [];
      }
    },
    canCreate() {
      return this.can("create", this.$createEntity(this.page.model.toLowerCase(), { id: 0 }));
    },
    canEdit() {
      return this.can("edit", this.$createEntity(this.page.model.toLowerCase(), { id: 0 }));
    },
    canDelete() {
      return this.can("delete", this.$createEntity(this.page.model.toLowerCase(), { id: 0 }));
    },
  },
  watch: {
    options: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_options", this.options);
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.load();
        }
      },
      deep: true,
    },
    advanced_search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
    show_from_app: {
      handler() {
        this.load();
      },
    },
  },
};
</script>

<style scoped>
.v-checkbox {
  display: flex;
}
.v-text-field >>> input {
  font-size: 12px !important;
}
.v-text-field >>> label {
  font-size: 12px !important;
}
.v-card {
  position: static !important;
  overflow: visible !important;
}
</style>